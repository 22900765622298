<script>
  export let chart;
  export let department;

  import { findUnitName, convertDateSmart } from "../utils.js";
  import { axiosAuth, commons } from "../auth.js";
  import Spinner from "svelte-spinner";
  import Switch from "svelte-switch";
  import Assignments from "./_Assignments.svelte";
  import { charts, units } from "../corpjobperson.js";
  import {
    SaveIcon,
    Link2Icon,
    InfoIcon,
    PlusIcon,
    Trash2Icon,
    Edit2Icon,
  } from "svelte-feather-icons";
  import { fade, scale, fly } from "svelte/transition";

  import { goto, url, params } from "@sveltech/routify";

  import { toast } from "@zerodevx/svelte-toast";

  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  function deleteEvent() {
    dispatch("delete", {
      name: departmentData.name,
      uuid: departmentData.uuid,
    });
  }

  function assignEvent() {
    let chartData = $charts.find((c) => c.uuid == chart);
    dispatch("assign", {
      unit: departmentData,
      chart: chartData,
    });
  }

  let tab = "assignments";
  let departmentData;
  let departmentDataString;
  let loading = false;
  let saving = false;
  let initialName = "";

  async function getDepartment() {
    loading = true;
    try {
      // /{clientUuid}/structure/{orgChartUuid}/department/{departmentUuid}
      const res = await $axiosAuth.get(
        "/" +
          $commons.orgUuid +
          "/structure/" +
          chart +
          "/department/" +
          department
      );
      departmentData = await res.data;
      departmentDataString = await JSON.stringify(res.data);
      initialName = await res.data.name;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      alert(error);
      console.log(res);
    } finally {
      loading = false;
    }
  }

  export function rerender() {
    getDepartment();
  }

  async function saveDepartment() {
    console.log("saving...", departmentData);
    saving = true;
    try {
      // /{clientUuid}/structure/{orgChartUuid}/department
      const res = await $axiosAuth.put(
        "/" + $commons.orgUuid + "/structure/" + chart + "/department",
        {
          divisionUuid: $params.department,
          departmentDto: departmentData,
        }
      );
      toast.push("Αποθηκεύτηκε");
      console.log("save dep");
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      // saving = false;
      //TODO: improve the following:
      // the timeout makes sure the "saving" animation is visible to the user even if very brief.
      setTimeout(() => {
        saving = false;
        $goto("./");
      }, 400);
      // the goto reloads the page, if the name changed: it is a quick and dirty way to update the unit name in the list

      // $goto("/structure/:chart/department/:department", {
      //   chart: $params.chart,
      //   department: $params.department
      // });

      // if (initialName != departmentData.name) {
      //   $goto("./");
      //   initialName = departmentData.name;
      // }
    }
  }

  const toggleDisable = () => {
    departmentData.disabled = !departmentData.disabled;
    saveDepartment();
  };

  $: {
    if ($commons && chart && department) {
      let promise = getDepartment();
    }
  }

  let mywidth;

  let showLabel = true;

  $: if (mywidth < 500) {
    showLabel = false;
  } else {
    showLabel = true;
  }

  // $: console.log(departmentData);
</script>

{#if departmentData && !loading}
  <div class="my-box" in:fade bind:clientWidth={mywidth}>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="title" class:disabled-unit={departmentData.disabled}>
          {departmentData.name} &nbsp;
          {#if departmentData.abbreviation}({departmentData.abbreviation}){/if}
        </div>
      </div>
      {#if departmentData.disabled || departmentData.disabled == false}
        <div
          class="column is-narrow"
          title={departmentData.disabled ? "Ανενεργό" : "Ενεργό"}
        >
          <Switch
            on:change={toggleDisable}
            onColor="#48c774"
            checked={!departmentData.disabled}
          />
        </div>
      {/if}
    </div>

    <!-- <div style="word-wrap: break-word; padding:3rem">
        {JSON.stringify(departmentData)}
      </div> -->

    <div class="tabs is-fullwidth">
      <ul>
        <li
          class:is-active={tab == "assignments"}
          on:click={() => (tab = "assignments")}
        >
          <a href="#">
            <span class="icon is-small">
              <Link2Icon />
            </span>
            <!-- Assignments -->
            Αναθέσεις
          </a>
        </li>
        <li class:is-active={tab == "info"} on:click={() => (tab = "info")}>
          <a href="#">
            <span class="icon is-small">
              <InfoIcon />
            </span>
            <!-- Info -->
            Πληροφορίες
          </a>
        </li>
      </ul>
    </div>

    <div class:disabled-unit={departmentData.disabled}>
      {#if tab == "assignments"}
        <Assignments />
      {:else}
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Name -->
              Όνομα
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.name}
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Abbreviation -->
              Συντόμευση
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.abbreviation}
                />
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">e-mail</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.email}
                />
              </p>
            </div>
          </div>
        </div>



        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Αναγνωριστικό Υποκαταστήματος
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.companyId}
                />
              </p>
            </div>
          </div>
        </div>

       <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Ειδικός κωδικός Υποκαταστήματος
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.companyCode}
                />
              </p>
            </div>
          </div>
        </div>


        {#if departmentData.unitsOfOrigin && departmentData.unitsOfOrigin.length}
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">
                <!-- Sources -->
                Προέλευση
              </label>
            </div>
            <div class="field-body">
              <div class="field">
                <!-- {JSON.stringify(departmentData.unitsOfOrigin)} -->
                <p class="control">
                  {#each departmentData.unitsOfOrigin as u}
                    <div
                      class="level"
                      style="margin-top:.5rem; margin-bottom:.5rem"
                    >
                      <div class="is-flex">
                        {findUnitName(u.sourceUnitUuid, $units)}
                      </div>

                      <div class="is-block">
                        {convertDateSmart(u.dateOfTransfer)}
                      </div>
                    </div>
                  {/each}
                </p>
              </div>
            </div>
          </div>
        {/if}

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Description -->
              Περιγραφή
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <textarea
                  style="height:2rem"
                  class="textarea"
                  placeholder=""
                  bind:value={departmentData.description}
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- City -->
              Πόλη
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.city}
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Address -->
              Διεύθυνση
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.address}
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Postal Code -->
              Ταχυδρομικός Κώδικας
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.postalCode}
                />
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Phone -->
              Τηλέφωνο
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.phone}
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              <!-- Fax -->
              Φαξ
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  bind:value={departmentData.fax}
                />
              </p>
            </div>
          </div>
        </div>

        <!-- <div style="word-wrap:break-word">{departmentDataString}</div> -->
      {/if}
    </div>

    <hr />

    <div class="sticky-bottom white-fade">
      <div class="level is-mobile">
        <button on:click={deleteEvent} class="button">
          <Trash2Icon size="1.5x" />
          {#if showLabel}&nbsp; Διαγραφή{/if}
        </button>

        {#if tab == "assignments"}
          <button class="button" on:click={assignEvent}>
            <PlusIcon size="1.5x" />
            {#if showLabel}&nbsp; Νέα ανάθεση{/if}
          </button>
        {:else}
          <button
            class="button is-link"
            on:click={saveDepartment}
            class:is-loading={saving}
          >
            <SaveIcon size="1.5x" />
            {#if showLabel}&nbsp; Αποθήκευση{/if}
          </button>
        {/if}
      </div>
    </div>

    <!-- <div class="box" style="word-wrap:break-word">
      {JSON.stringify(departmentData)}
    </div> -->
  </div>
{:else}
  <div in:fade|local class="columns is-centered">
    <div style="margin-top:5rem">
      <Spinner size="40" speed="750" thickness="2" gap="40" />
    </div>
  </div>
{/if}

<style>
  :global(.sticky-bottom) {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    /* z-index: 30; */
  }

  :global(.white-fade) {
    /* background: rgb(255, 255, 255); */
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0.8) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

:global(.white-fade-top) {
    /* background: rgb(255, 255, 255); */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0.8) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  :global(.disabled-unit) {
    opacity: 0.5;
    transition: opacity ease 500ms;
  }

  .my-box {
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 1.25rem;
  }
</style>
